h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #778492;
}

.header {
    background-color: 'rgb(255,250,250)';
    margin-top: 20px;
    padding-top: 20px;
	margin-bottom: 20px;
    padding-bottom: 20px;
}

.profile-image {
    margin-right: 30px;
    float: left;
}

.profile-content .name {
    color: #49515a;
    font-size: 38px;
    margin-bottom: 5px;
    margin-top: 30px;
}

.profile-content .desc {
    color: #778492;
    font-family: "Lato", arial, sans-serif;
    font-weight: 400;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 15px;
}

.profile-content .social a {
    background: #b0b7bf;
    width: 36px;
    height: 36px;
    display: inline-block;
    border-radius: 50%;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    color: #fff;
    text-align: center;
}

.profile-content .social a:hover {
    background: #778492;
}

.profile-content .social a .svg-inline--fa {
    font-size: 20px;
    margin-top: 8px;
}

.btn {
    font-size: 15px;
    font-weight: bolder;
}

@media (max-width: 767.98px) {
    .header {
        text-align: center;
    }
      .header .profile-image {
        float: none !important;
        margin: 0 auto;
    }
      .header .profile-content {
        float: none !important;
        text-align: center;
    }
}

@media (min-width: 1400px) {
    .container {
      width: 1360px;
      max-width: inherit;
    }
}